.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}

.modal-content {
    width: 600px;
    flex-shrink: 0;
    background-color: white;
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 90%;
    z-index: 1001;
    border-radius: 15px;
    background: #FFF;
}

.delete-modal-content{
    width: 600px;
    flex-shrink: 0;
    background-color: white;
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 90%;
    z-index: 1001;
    border-radius: 15px;
    background: #FFF; 
    align-items: center;
}

.close-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.modal-overlay {
    animation: modalFadeIn 0.5s;
}

.delete_modalText {
    text-align: center;
    color: #000;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.44px;
    margin-bottom: 31px;
    margin-top: 25px;
}

.modalDesc {
    color: #000;
    font-family: 'Raleway', sans-serif;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: left;
    margin-bottom: 20px;
    line-height: 23px;
}

.delete_modalBtnGroup {
    text-align: center;
    display: flex;
    flex-direction: column;
    
}

.delete_agreeBtn {
    border-radius: 5px;
    width: 100%;
    border: 1px solid #DC3545;
    background: #ED99A1;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    color: black;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    line-height: normal;
    margin-bottom: 20px;
    color: var(--white, #ffffff);
    padding: 11px 25px;
    margin-bottom: 12px;
    font-size: 14px !important;
    font-weight: 500;
}

.delete_disagreeBtn {
    border-radius: 5px;
    border: 1px solid black;
    background: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    color: black;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    line-height: normal;
    display: inline;
    padding: 11px 25px;
    cursor: pointer;
    font-size: 14px;
    margin: 0px;
}