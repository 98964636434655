/* General styles with v_3 prefix */
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
    font-family: "Inter" !important;
}

.v_3sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background: #F9F9F9;
    overflow-x: hidden;
    /* transition: 0.5s; */
    border-right: 1px solid #E5E5E5;
}

.v_3sidebar--open {
    width: 250px;
    z-index: +0999009999;
}

.v_3sidebar a {

    color: black !important;

}



.v_3closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.v_3openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
}

.v_3openbtn:hover {
    background-color: #444;
}

.v_3main {
    /* transition: margin-left 0.5s; */
    padding: 16px;
}

#v_3main {
    width: 100%;
    display: flex !important;
}

.v3_chat_bg {
    position: relative;
    overflow: auto;
    background: #FCFCFC;
    height: 100vh;
}

.text-area {
    border: 1px solid #000 !important;
    background-color: #192733 !important;
}

.v_3main--shift {
    margin-left: 250px;
}

.v3setsidebaricon {
    margin: 10px 7px;
    width: 10%;
    display: flex;
    justify-content: space-between;
}

.v3setonsidebaricon {
    margin: 10px 14px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.v3chat__footer {
    position: fixed;
    /* Fixes the footer at the bottom of the page */
    width: 80%;
    /* Full width to fit the screen */
    padding: 20px;
    padding-top: 0px;
    /* Optional padding for aesthetics */
    bottom: 0px;
    background-color: #FCFCFC;
}

#outsidebar {
    background-color: #FCFCFC;
}

.v3chat__date-wrapper {}

.v3chat__input {
    /* background: #FCFCFC; */
    color: rgb(74, 74, 74);
    padding: 20px 10px;
    border-radius: 22px;
    flex: 1 1;
    /* height: 100%; */
    border: 1px solid #E5E5E5;
    min-height: 64px;
    max-height:100px;
    height: 60px;
}

.v3chat__content {
    text-align: center;
    margin-top: 20px;
    z-index: -1;
    flex-grow: 1;
    overflow: auto;
}

.v3chat__input {
    overflow: auto;
}

.v3chat__content::-webkit-scrollbar,
.v3chat__input::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari */
}

/* For Firefox */
.v3chat__content,
textarea {
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
}

.chat__input-icon-2 {
    position: absolute;
    left: 60px;
    outline: none !important;
    box-shadow: none !important;
}

.v3_dropdowninchat {
    padding: 12px 22px;
    margin-bottom: 0px !important;
    /* border: 1px solid black; */
    /* border-radius: 5px; */
    background-color: #F9F9F9;
    border:1px solid #E5E5E5;
    border-radius: 6px ;
    margin: 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    margin-left: 10px;
}

.v3_dropdowninchat_filter {
    padding: 10px 9px;
    border: 1px solid #f1f1f1;
    border-radius: 20px;
    background-color: white;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    margin-top: 5px;
    max-width: 100px;
    cursor: pointer;
}

.v3_dropdowninchat_search {
    padding: 10px 9px;
    border: 1px solid #f1f1f1;
    border-radius: 20px;
    background-color: white;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    margin-top: 5px;
    max-width: 350px;
    cursor: pointer;
    text-align: center;
}

.v3_dropdowninchat_filter_task {
    padding: 10px 9px;
    border: 1px solid #f1f1f1;
    border-radius: 20px;
    background-color: white;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    margin-top: 5px;
    width: 100%;
    cursor: pointer;
}

.v3_menuItems {
    position: absolute;
    width: 35%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    background-color: white !important;
    margin: 0px 10px;
    z-index: +9999 !important;
}

.v3_menuItems_taskbar {
    position: absolute;
    width: 45%;
    right: 0px;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    background-color: white;
    margin: 0px 10px;
    z-index: +9999;
    cursor: pointer;
}


.v3_togglebtn {
    margin-left: 10px;
    width: 10px;
    height: 10px;
    border-left: 2px solid #ECECEC;
    border-bottom: 2px solid #ECECEC;
    transition: transform 0.2s ease;

}

.v3_dropdown_optionvalue {
    display: flex;
    z-index: +1000 !important;
}

.v3_dropdown_optionvalue:hover {
    background-color: #f9f9f9;

}
.v3_dropdown_optionvaluefatymenu:hover{
    background-color: #f9f9f9;
    border-radius: 10px;
}

.v3_radiobutton {

    transform: scale(1.5);
    /* Adjust the scale as needed */
    -webkit-transform: scale(1.5);
    /* For Safari */
    -moz-transform: scale(1.5);
    /* For Firefox */
    -ms-transform: scale(1.5);
    /* For Internet Explorer */
    -o-transform: scale(1.5);
    /* For older Opera versions */
    margin-right: 10px;
    /* Optional: Add margin for spacing */

}

.v3_list_main {
    margin-top: 20px;
}

.v3sidebarlist {
    display: flex;
    justify-content: flex-start;
    margin: 10px 6px;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.v3sidebarlist:hover {
    display: flex;
    justify-content: flex-start;
    margin: 10px 6px;
    background: #ECECEC;

    padding: 10px;
    border-radius: 5px;
}

.v3_sec_sidebar {
    width: 0;
    position: fixed;
    z-index: 1;
    height: 100%;
    top: 0;
    left: 250px;
    background-color: #f9f9f9;
    overflow-x: hidden;
    transition: 0.5s;
    border-right: 1px solid #E5E5E5;

}

.v3_sec_sidebar.open {
    width: 250px;
    /* Set your desired width */
}

.v3_sec_sidebar__closebtn {
    font-size: 36px;
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
    color: white;
    /* Close button color */
}

.sidebar-content {
    padding: 20px;
    /* Adjust padding as needed */
    color: white;
    /* Text color */
}

.v3_chatlisr {
    display: flex;
    justify-content: space-between;
    margin: 10px 6px;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.v3_chatlisr:hover {
    background-color: #E5E5E5;
}

.v3_search {
    background-color: white;
    border-radius: 20px;
    padding: 10px 0px 10px 30px;
    border: 1px solid #E5E5E5;

}

.px-3 {
    padding-left: 10;
    padding-right: 10;
}

.crossicon {
    position: absolute;
    top: 2px;
    right: 0;
    cursor: pointer;
}

.searchiconsetting_v3 {
    position: absolute;
    top: 20px;
    left: 25px;
}

.v3_searchMain {
    display: flex;
    justify-content: 'space-between';
    padding-top: 20px;
}

.filtericonsetting_v3 {
    position: absolute;
    top: 14px;
    left: 9px;
}

.filtericoncroxx_v3 {
    position: absolute;
    top: 8px;
    left: 9px;
}

.v3_chatlist_title {
    white-space: nowrap;
    /* Prevents text from wrapping to a new line */
    overflow: hidden;
    /* Hides overflow */
    text-overflow: ellipsis;
    /* Adds ellipsis (...) when text is too long */
    max-width: 200px;
    /* Adjust the width to control how much text is shown */
}

/* On smaller screens, adjust styles */
@media screen and (max-height: 450px) {
    .v_3sidebar {
        padding-top: 1px;
    }

    .v_3sidebar a {
        /* font-size: 18px; */
    }
}

.result_3 {
    font-family: Inter;
    font-size: 14px;
    font-weight: bold;
    line-height: 16.94px;
    text-align: left;
    padding: 13px 24px;

}

.stickyTop {
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #f9f9f9;
}

.box_1 {
    padding: 20px 18.6px;
    background-color: white;
    margin: 13px 24px;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    cursor: pointer;
}

.box_1_b {
    font-size: 14px;
    line-height: 16.94px;
    margin-bottom: 7px !important;
}

.box_1_p {
    font-size: 12px;
    line-height: 14.52px;
    font-weight: 400;
}

.box_2 {
    padding: 20px 18.6px;
    background-color: white;
    /* #ECECEC; */
    margin: 13px 24px;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
}

.box_2:hover {
    background-color: #ECECEC;
    border: 1px solid #E5E5E5;
}

.three_dots {
    position: absolute;
    top: 10px;
    right: 15.5px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.v3_three_dots_options {
    background-color: white;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    position: absolute;
    top: 35px;
    right: 15px;
    width: 131px;
    z-index: 1000;
}

.v3_three_dots_options_sidebar {
    background-color: white;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    position: absolute;
    top: 35px;
    right: 15px;
    width: 131px;
    z-index: 1000;
}

.v3_three_dots_options_box {
    display: flex;
    padding: 15px 12px;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    cursor: pointer;
}

.v3_three_dots_options_box:hover {
    background-color: #ECECEC;
}

.v3_three_dots_options_text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    margin: 0px !important;
    padding-left: 11px;
}

.v3_three_dots_options_icon {
    width: 15px;
    height: 15px;
}

.box_2_b {
    font-size: 14px;
    line-height: 16.94px;
    margin-bottom: 7px !important;
}

.box_2_p {
    font-size: 12px;
    line-height: 14.52px;
    font-weight: 400;
}

.v3_dropdown {
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    position: absolute;
    background-color: #FCFCFC;
    min-width: 224px;
    z-index: 1;
    bottom: 50px;
    left: -50px;
    z-index: +999;
}

.v3_dropdown span {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px !important;

}

.v3_dropdown span:hover {
    background-color: #f1f1f1;
}

/* Show the dropdown when hovering over the button */
.v3_hover-text:hover .v3_dropdown {
    display: block;
}

.v3_more_dropdown {
    position: absolute;
    background-color: white;
    min-width: 200px;
    z-index: 1;
    left: -190px;
    top: 180px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
}

.v3_more_dropdown span {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    border-bottom: 1px solid #E5E5E5;
    font-size: 14px;
}

.v3_more_dropdown span:hover {
    background-color: #f1f1f1;
}

.v3_taskbutton-round {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border-radius: 20px;
    cursor: pointer;
}

.v3_modal_table_heading {
    display: flex;
    padding-left: 30px;
    padding-right: 25px;
}

.v3_modal_table_heading p {
    width: 33.33%;
    font-size: 14px;
    font-weight: 500 !important;
}

.v3_modal_table_body {
    display: flex;
    border-radius: 5px;
    border: 1px solid #E5E5E5;
    padding-left: 30px;
    padding-right: 25px;
    align-items: center;
    margin-bottom: 12px;
}

.v3_modal_table_body p {
    width: 33.33%;
    font-size: 12px;
    font-weight: 400;
    margin: 0px;
    padding: 20px 0px;
}

.v3_modal_table_body .v3_modal_table_body_input {
    width: 33.33%;
    align-items: right;
    display: flex;
    justify-content: end;
}

.v3_modal_table_body .v3_modal_table_body_input input {
    width: 20px;
    height: 20px;
    margin-left: auto;
    background-color: white;
    color: black;
}

.v3_modal_table_button {
    background-color: black;
    color: white;
    border-radius: 5px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: center;
    width: 100%;
    padding: 12.5px;
}


.select_file_modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: +99999999999999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.select_file_modal_content {
    background-color: #fefefe;
    margin: auto;
    padding: 32px 34px;
    border: 1px solid #888;
    width: 600px;
    border-radius: 10px;
    position: relative;
    max-height: 534px;
    overflow: auto;
    border-radius: 19px;
}

.toast {
    color: black
}



.file_upload_input {
    padding: 10px 25px;
    height: 60px;
    position: relative;
    display: flex;
    align-items: center;
    width: 90%;
    margin: auto;
    background-color: #F7F7F7;
    border: 1px solid #E5E5E5;
}

.category_name {
    padding: 10px;
    color: gray;
    width: 100%;
    background: white;
    margin: 0px !important;
}

.category_name:hover {
    background: rgb(235, 234, 234);
    border-radius: 5px;
}

.file_upload_fileId {
    display: flex;
    align-items: center;
    padding: 16px 25px;
    border-radius: 50px;
    border: 1px solid #E5E5E5;
    width: fit-content;
    margin-left: auto;
    background-color: #F9F9F9;
    width: 200px;
}

.v3_airesponse {
    display: flex;

}

.v3_imgchat {
    border-radius: 38px;
    border: 1px solid gray;
    padding: 6px 4px;
    background: white;
    margin: 0 10px 0px 0px;
}

.v3_top-10px {
    margin-top: 5px;
}

.v3_chatnew_tooltip-text {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    width: 100px;
    color: white;
    font-size: 12px;
    background-color: #192733;
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
    margin-top: 40px auto;
}


.v3_chatnew_tooltip-text::before {
    content: "";
    position: absolute;
    transform: rotate(90deg);
    background-color: #192733;
    z-index: 1;
}

.v3_chatnew_hover-text:hover .v3_chatnew_tooltip-text {
    visibility: visible;
}

.v3_chatnew_tooltip2-text {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    width: 100px;
    color: white;
    font-size: 12px;
    background-color: #192733;
    border-radius: 10px;
    padding: 10px;
    margin-left: -120px;
}


.v3_chatnew_tooltip2-text::before {
    content: "";
    position: absolute;
    transform: rotate(90deg);
    background-color: #192733;
    z-index: 1;
}

.v3_chatnew_hover2-text:hover .v3_chatnew_tooltip2-text {
    visibility: visible;
}


.text_black {
    color: black !important;
}

.select_assistat_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.v3_notwork {
    cursor: no-drop;
    pointer-events: none;
    opacity: .3;
}

.down_arrow_icon {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    border: 1px solid #E5E5E5;
    color: black;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.v3_tab_navigation {
    padding: 10px 30px;
}

.v3_tab_navigation button {
    margin-right: 5px;
}

.loader-v3 {

    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    position: absolute;
    left: calc(43% - 10px);
    /* Center the loader */
    top: calc(50% - 10px);
    /* Center the loader */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.position-relative {
    position: relative;
}

.disabled-button {
    cursor: no-drop;
    pointer-events: none;
    background-color: #4e4e4e;
}

.active_tab {
    background-color: #dddddd;
    border-radius: 5px;
}

.v3_tab_button {
    overflow: unset;
    border: 0px;
    padding: 2px 5px;

}
.padding-left-10px{
    padding-left: 30px;
}
.fatAdded{
    padding: 20px 14px 40px !important;
}
.file_spinner {
    margin-top: 5px;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid black;
    border-radius: 50%;
    animation: spin 0.6s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  