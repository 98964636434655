/* @import url(./App.darktheme.css); */
.app {
  width: 100%;
  min-height: 100vh;
  background: #f5f5f5;
  position: relative;
}

.app__mobile-message {
  display: none;
}

.app-content {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  /* max-width: 1450px; */
  margin: 0 auto;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 100;
  display: flex;
}

@media screen and (max-width: 500px) {
  .app__mobile-message {
    padding-top: 200px;
    text-align: center;
    font-size: 1.2rem;
    display: block;
  }

  .app-content {
    display: none;
  }
}

@media screen and (min-width: 1450px) {
  /* .app {
    padding: 20px;
  }
  .app-content {
    height: calc(100vh - 40px);
  } */
}

/* CSS for the spinner */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #ffffff;
  /* Change color as needed */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spinner 1s linear infinite;
  margin-right: 12px;
  /* Adjust spacing as needed */
}

/* Keyframes for the spinnerner animation */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.dateWiseWrap {
  display: flex;
  justify-content: space-between;
  margin: 10px 6px;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

.datewise {
  font-size: 16px;
  margin: 0;
}